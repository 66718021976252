<!--
- Software
-- Reference Type Id: 40
-- 2024.05.01
-->

<template>
	<div>
		<section class="mb-5">
			<h3>Format Options</h3>

			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Computer"
								value="computer"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Type_Computer">
								Computer software (Linux, Mac, Windows, etc.)
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Mobile"
								value="mobile"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Type_Mobile">
								A mobile app
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Reference"
								value="reference"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Type_Reference">
								A reference entry in a mobile app
							</label>
						</div>
					</div>
				</div>
			</div>
		</section>
		
		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>
			
			<form-textarea-reference-wizard
				v-show="type === 'reference'"
				label="Entry Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="entry" />
		
			<form-textarea-reference-wizard
				label="Software or App Name"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />

			<form-input-reference-wizard
				label="Version Number"
				help-text="Enter the version number exactly as it appears in the software or app. You can find this in the About settings or the mobile app store listing."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="version" />
			
			<form-input-reference-wizard
				label="Software Platform"
				help-text="List the platform the software is run on (e.g., Windows, iOS, Android, etc.)."
				@generate-reference-preview="generateReferencePreview"
				v-model="platform" />

			<control-publishers
				v-show="type === 'computer'"
				:is-show-city-state="false"
				label="Software Publisher"
				help-text-prop="Don't include business titles (e.g., Inc., Co, Ltd.)."
				field-type="reference" /> 

			<control-publishers
				v-show="type !== 'computer'"
				:is-show-city-state="false"
				label="Mobile Platform Store"
				button-text="store"
				help-text-prop="Enter the name of the mobile store listing the application."
				field-type="reference" /> 

			<form-input-reference-wizard
				label="Publication Year"
				help-text="List the year the software or app was published."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="publicationDate" />

			<form-input-reference-wizard
				label="URL"
				help-text="Type the full url for the software website or app store listing."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="url" />

		</section>
	</div>
</template>

<script>
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeSoftware',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = ['author', 'groupAuthor'];

			this.addContributorRole({
				type: 'author',
			});

			this.addContributorRole({
				addDefault: true,
				type: 'groupAuthor',
			});
			
			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},//e:calcContributors
		
	},
	mounted(){
		if(this.type === ''){
			this.type = 'computer';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
	},
}
</script>