var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "mb-5" }, [
      _c("h3", [_vm._v("Format Options")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [_vm._v("Choose a type")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Episode",
                  value: "episode",
                },
                domProps: { checked: _vm._q(_vm.type, "episode") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "episode"
                    },
                    _vm.typeChange,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(0),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Series",
                  value: "series",
                },
                domProps: { checked: _vm._q(_vm.type, "series") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "series"
                    },
                    _vm.typeChange,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(1),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Interview",
                  value: "interview",
                },
                domProps: { checked: _vm._q(_vm.type, "interview") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "interview"
                    },
                    _vm.typeChange,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(2),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type != "interview",
              expression: "type != 'interview'",
            },
          ],
          staticClass: "form-group mb-0 py-2",
        },
        [
          _c("label", [
            _vm._v(
              "What kind of podcast or radio episode are you referencing?"
            ),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.kind,
                  expression: "kind",
                },
              ],
              staticClass: "form-select",
              attrs: {
                "aria-label":
                  "What kind of podcast or radio episode are you referencing?",
              },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.kind = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.calcContributors,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "audio" } }, [
                _vm._v("\n\t\t\t\t\tAn audio podcast\n\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "video" } }, [
                _vm._v("\n\t\t\t\t\tA video podcast\n\t\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "radio" } }, [
                _vm._v("\n\t\t\t\t\tA radio broadcast\n\t\t\t\t"),
              ]),
            ]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type != "interview",
              expression: "type != 'interview'",
            },
          ],
          attrs: {
            label: "Series Title",
            "help-text": _vm.calcTitleHelpText,
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "interview",
              expression: "type == 'interview'",
            },
          ],
          attrs: {
            label: "Interview Title",
            "help-text":
              'Capitalize all major words of the title and subtitle, including proper nouns, e.g. "John Doe Recalls His Encounter with Bigfoot"',
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "episode",
              expression: "type == 'episode'",
            },
          ],
          attrs: {
            label: "Episode Title",
            "help-text": _vm.calcTitleHelpText,
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.episodeTitle,
            callback: function ($$v) {
              _vm.episodeTitle = $$v
            },
            expression: "episodeTitle",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "series",
              expression: "type == 'series'",
            },
          ],
          attrs: {
            label: "Years the Series Aired",
            "help-text":
              'Type the years that the series first aired. If the show is still being produced, the second year should be "present".',
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.publicationDate,
            callback: function ($$v) {
              _vm.publicationDate = $$v
            },
            expression: "publicationDate",
          },
        }),
        _vm._v(" "),
        _c("form-datepicker", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type != "series",
              expression: "type != 'series'",
            },
          ],
          attrs: {
            id: "RefWiz_PublicationDate",
            label: "Episode Date",
            "help-text":
              "Enter the date the episode first aired or was published.",
            "is-common-use": true,
            "is-all-common-use": _vm.type === "episode",
            "paper-format": 0,
          },
          on: {
            "date-set": _vm.dateSetPublication,
            "date-clear": _vm.dateClearPublication,
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "episode",
              expression: "type == 'episode'",
            },
          ],
          attrs: {
            label: "Episode Number",
            "help-text": "Type the number for the episode (e.g., 114).",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.episodeNumber,
            callback: function ($$v) {
              _vm.episodeNumber = $$v
            },
            expression: "episodeNumber",
          },
        }),
        _vm._v(" "),
        _vm.type == "episode"
          ? _c("form-time-split-reference-wizard", {
              attrs: {
                "engine-value": "length",
                label: "Recording Length",
                "help-text": "Enter the full length of the program recording.",
                "is-common-use": true,
              },
              on: {
                "generate-reference-preview": _vm.generateReferencePreview,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("control-publishers", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type != "interview",
              expression: "type != 'interview'",
            },
          ],
          attrs: {
            label: "Production Company",
            "button-label": "producer",
            "field-type": "reference",
            "is-show-city-state": false,
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type != "interview",
              expression: "type != 'interview'",
            },
          ],
          attrs: {
            label: "Platform",
            "help-text":
              "Enter the platform if it is different from the Production Company.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.platform,
            callback: function ($$v) {
              _vm.platform = $$v
            },
            expression: "platform",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "interview",
              expression: "type == 'interview'",
            },
          ],
          attrs: { label: "Archive Name" },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.archiveName,
            callback: function ($$v) {
              _vm.archiveName = $$v
            },
            expression: "archiveName",
          },
        }),
        _vm._v(" "),
        _c("control-publishers", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "interview",
              expression: "type === 'interview'",
            },
          ],
          attrs: {
            label: "Archive Institution",
            "button-label": "institution",
            "field-type": "reference",
            "help-text": _vm.calcHelpTextArchiveInstitution,
            "is-show-city-state": false,
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "URL",
            "help-text":
              "Type or paste the entire URL. You can use a URL shortener if it is too long (e.g., bit.ly).",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.url,
            callback: function ($$v) {
              _vm.url = $$v
            },
            expression: "url",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "RefWiz_Type_Episode" },
      },
      [
        _vm._v(
          "\n\t\t\t\t\t\t\tA single podcast or radio episode\n\t\t\t\t\t\t\t"
        ),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            role: "tooltip",
            title:
              "This is used for citing specific episodes from a series or show.",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-check-label", attrs: { for: "RefWiz_Type_Series" } },
      [
        _vm._v(
          "\n\t\t\t\t\t\t\tAn entire podcast or radio series\n\t\t\t\t\t\t\t"
        ),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            "data-bs-html": "true",
            role: "tooltip",
            title:
              "Use then when you aren&lsquo;t referring to a single episode, but a series/show in general.",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "RefWiz_Type_Interview" },
      },
      [
        _vm._v(
          "\n\t\t\t\t\t\t\tA radio interview in a digital archive\n\t\t\t\t\t\t\t"
        ),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            role: "tooltip",
            title:
              "Use this for archived radio interviews only.  If the interview is not archived, cite it as a single podcast or radio episode.",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }