<!--
- Manuscript
-- Reference Type Id: 27
-- 2023.10.18
-->

<template>
	<div>
		<section class="mb-5">
			<h3>Format Options</h3>

			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Unpublished"
								value="unpublished"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Type_Unpublished">
								Unpublished manuscript
								<span
									class="svg bi-info-circle has-tooltip" 
									data-bs-toggle="tooltip" 
									data-bs-placement="bottom" 
									role="tooltip"
									title="Unpublished references are owned by the author, but can be found in some university libraries or departments." />
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Preparation"
								value="preparation"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Type_Preparation">
								Manuscript in preparation
								<span 
									class="svg bi-info-circle has-tooltip" 
									data-bs-toggle="tooltip" 
									data-bs-placement="bottom" 
									role="tooltip"
									title="This is a manuscript that is being worked on by an author." />
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Submitted"
								value="submitted"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Type_Submitted">
								Manuscript submitted for publication
								<span
									class="svg bi-info-circle has-tooltip" 
									data-bs-toggle="tooltip" 
									data-bs-placement="bottom" 
									role="tooltip"
									title="This reference type is for manuscripts that have not yet been accepted by a publisher.  If the manuscript has been accepted, create an In-press article." />
							</label>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="mb-5">
			<control-contributor 
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-textarea-reference-wizard 
				label="Manuscript Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="workTitle" />

			<form-input-reference-wizard 
				label="Year Completed"
				help-text="Since manuscripts haven't been published yet, include the year the work was finished."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="publicationDate" />

			<control-publishers
				button-text="affiliation"
				label="Institutional Affiliation"
				:help-text-prop="calcHelpTextInstitutionalAffiliation"
				field-type="reference" /> 

			<control-toggle-select 
				:label="toggleSelect.doiUrl.label"
				:is-common-use="toggleSelect.doiUrl.isCommonUse"
				:options="toggleSelect.doiUrl.options"
				group-name="doiUrl" />
		</section>
	</div>
</template>

<script>
import config from '@/config';
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeManuscript',
	mixins: [mixinReferenceTypeGenesis],
	computed:{
		calcHelpTextInstitutionalAffiliation(){
			let helpText = "Manuscripts list the author's institutional affiliation instead of a publisher.";
			if(this.referenceFormatTypeId === config.enums.Format.MLA9){
				helpText += " Include the department, institution, and location information separated by a comma if possible";
			}
			return helpText;
		},
		calcPlaceholderInstitutionalAffiliation(){
			if(this.referenceFormatTypeId === config.enums.Format.MLA9){
				return "Dept. of Cryptozoology, PERRLA U, Nashville, TN"
			} else {
				return "Department of Cryptozoology, PERRLA University.";
			}
		},
	},
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = [];

			// Author
			this.addContributorRole({
				addDefault: true,
				type: 'author',
			});

			// Group Author
			this.addContributorRole({
				type: 'groupAuthor',
			});

			// Editor
			this.addContributorRole({
				type: 'editor',
			});

			if(!this.isRefRulesEmpty) {
				// Load Based on previously entered data in Step 3 (or an Edit)
				this.usedContributorTypes = ['author', 'groupAuthor', 'editor'];
			}

			this.sortContributorTypes();
		},//e:calcContributors
	},
	mounted(){
		if(this.type === ''){
			this.type = 'unpublished';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
	},
}
</script>