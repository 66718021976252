var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "mb-5" }, [
      _c("h3", [_vm._v("Format Options")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [_vm._v("Choose a type")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Computer",
                  value: "computer",
                },
                domProps: { checked: _vm._q(_vm.type, "computer") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "computer"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "RefWiz_Type_Computer" },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t\t\tComputer software (Linux, Mac, Windows, etc.)\n\t\t\t\t\t\t"
                  ),
                ]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Mobile",
                  value: "mobile",
                },
                domProps: { checked: _vm._q(_vm.type, "mobile") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "mobile"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "RefWiz_Type_Mobile" },
                },
                [_vm._v("\n\t\t\t\t\t\t\tA mobile app\n\t\t\t\t\t\t")]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Reference",
                  value: "reference",
                },
                domProps: { checked: _vm._q(_vm.type, "reference") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "reference"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "RefWiz_Type_Reference" },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t\t\tA reference entry in a mobile app\n\t\t\t\t\t\t"
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "reference",
              expression: "type === 'reference'",
            },
          ],
          attrs: {
            label: "Entry Title",
            "help-text": _vm.calcTitleHelpText,
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.entry,
            callback: function ($$v) {
              _vm.entry = $$v
            },
            expression: "entry",
          },
        }),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          attrs: {
            label: "Software or App Name",
            "help-text": _vm.calcTitleHelpText,
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Version Number",
            "help-text":
              "Enter the version number exactly as it appears in the software or app. You can find this in the About settings or the mobile app store listing.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.version,
            callback: function ($$v) {
              _vm.version = $$v
            },
            expression: "version",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Software Platform",
            "help-text":
              "List the platform the software is run on (e.g., Windows, iOS, Android, etc.).",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.platform,
            callback: function ($$v) {
              _vm.platform = $$v
            },
            expression: "platform",
          },
        }),
        _vm._v(" "),
        _c("control-publishers", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "computer",
              expression: "type === 'computer'",
            },
          ],
          attrs: {
            "is-show-city-state": false,
            label: "Software Publisher",
            "help-text-prop":
              "Don't include business titles (e.g., Inc., Co, Ltd.).",
            "field-type": "reference",
          },
        }),
        _vm._v(" "),
        _c("control-publishers", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type !== "computer",
              expression: "type !== 'computer'",
            },
          ],
          attrs: {
            "is-show-city-state": false,
            label: "Mobile Platform Store",
            "button-text": "store",
            "help-text-prop":
              "Enter the name of the mobile store listing the application.",
            "field-type": "reference",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Publication Year",
            "help-text": "List the year the software or app was published.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.publicationDate,
            callback: function ($$v) {
              _vm.publicationDate = $$v
            },
            expression: "publicationDate",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "URL",
            "help-text":
              "Type the full url for the software website or app store listing.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.url,
            callback: function ($$v) {
              _vm.url = $$v
            },
            expression: "url",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }