<!--
- Publishers > Block > Form Label and Input
-- ex: Name
-- 2024.11.22 (PO and Add-In Only)
-->

<template>
	<div class="form-group form-group-split mb-2">
		<div class="flex-shrink-1" v-if="isShowCityState">
			<label
				class="col-form-label"
				:for="fullId"
				v-html="label" />
		</div>
		<div class="w-100">
			<input
				autocomplete="off"
				ref="focusElement"
				type="text"
				:class="['form-control', {
					'focus-first': isFocusOnAdd,
					'form-control-primary': showIsCommonUse,
				}]"
				:id="fullId"
				:value="value"
				@blur="blurInput"
				@input="typeInput($event.target.value)"
				v-debounce:750ms="debouncedGenerateReference" /> 
		</div>
	</div>
</template>

<script>
import config from '@/config';
import mixinId from '@/mixins/id';

export default {
	name: 'PublishersInput',
	mixins: [mixinId],
	props:{
		isFocusOnAdd:{	// used to focus an element when a new name is added
			default: false,
			type: Boolean,
		},
		isShowCityState: {
			default: true,
			type: Boolean
		},
		label: {
			type: String,
		},
		value: {
			type: String,
		},
	},
	computed:{
		fullId(){
			let urlFriendlyLabel = this.label.replace(/\W+/g, '-').toLowerCase() || '';
			return this.safeId + '_' + urlFriendlyLabel;
		},
		showIsCommonUse(){
			let _referenceFormatTypeId = 0;

			if(config.platformId === config.enums.Platform.ONLINE){
				if(this.$store.state.paperEdit.meta.PaperFormatVersionID){
					_referenceFormatTypeId = this.$store.state.paperEdit.meta.PaperFormatVersionID;
				}
			} else {
				_referenceFormatTypeId = this.$store.state.formatVersionID;
			}
			
			switch(_referenceFormatTypeId){
				case 0:	// when there isn't a format type id the user is probably creating from the /ReferenceList (follow the same rules as APA7 here)
				case config.enums.Format.APA7:
				case config.enums.Format.MLA9:
					if(this.isFocusOnAdd){
						// Name field should be common blue
						if(this.value === '') {
							return true;
						} else {
							return false;
						}	
					}
					return false;
				case config.enums.Format.Turabian9:
					if(this.value === '') {
						return true;
					} else {
						return false;
					}	
				default:
					return false;
			}
		},
	},
	methods: {
		blurInput(){
			this.$emit('generate-reference-preview');
		},
		
		debouncedGenerateReference(){
			this.$store.dispatch('referenceRules/generateReferencePreview');
		},//e:debouncedGenerateReference

		typeInput(value){
			this.$store.commit('forms/MARK_FORM_DIRTY_BY_ID', {
				formId: 'formReferenceWizard',
			});

			this.$emit('input', value);
		}//e:typeInput
	},
	created(){
		this.emitter.on('focusFirstPublisherField', ()=>{
			if(this.isFocusOnAdd){
				let $focusElCollection = this.$parent.$parent.$el.getElementsByClassName('focus-first');
				$focusElCollection[$focusElCollection.length - 1].focus();
			}
		});
	},
	destroyed(){
		this.emitter.off('focusFirstPublisherField');
	},
}
</script>
