<!--
- Music
-- Reference Type Id: 28
-- 2024.05.14
-->

<template>
	<div>
		<section class="mb-5">
			<h3>Format Options</h3>

			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input 
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Album"
								value="album"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Type_Album">
								An album or stand-alone piece
								<span 
									class="svg bi-info-circle has-tooltip" 
									data-bs-toggle="tooltip" 
									data-bs-placement="bottom" 
									role="tooltip"
									title="This is for citing a piece of music in its entirety.  It may be an album with multiple songs or a single piece released by itself." />
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input 
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Song"
								value="song"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Type_Song">
								A song or track on an album
								<span 
									class="svg bi-info-circle has-tooltip" 
									data-bs-toggle="tooltip" 
									data-bs-placement="bottom" 
									role="tooltip"
									title="If you are citing a piece that is only a part of a larger album, use this reference type." />
							</label>
						</div>
					</div>
				</div>
			</div>

			<!-- Kind -->
			<div class="form-group mb-0 py-2">
				<label>How was the piece performed?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="How was the piece performed?">
					<option value="original">
						Recorded by the original artist
					</option>
					<option value="cover">
						Performed by someone else; not the original artist (e.g. Classical music, covers)
					</option>
				</select>
			</div>
		</section>

		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>
			
			<form-textarea-reference-wizard 
				label="Album or Work Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="albumTitle" />

			<form-textarea-reference-wizard 
				v-show="type === 'song'"
				label="Song Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="songTitle" />

			<form-input-reference-wizard 
				label="Copyright Year"
				help-text="This is the year the album was copyrighted and/or released."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="publicationDate" />

			<form-input-reference-wizard 
				label="Date or Year Recorded"
				help-text="If the date or year the work was recorded is available and different from the copyright date, include it here (e.g., April 1, 2020)."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="recordDate" />

			<form-input-reference-wizard 
				v-show="kind === 'cover'"
				label="Original Publication Year"
				help-text="Include the year the work was originally published."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="originalPublicationDate" />

			<form-input-reference-wizard 
				label="Special Edition"
				help-text="Only include an special edition title if it is different from the original album (e.g., Deluxe edition)."
				@generate-reference-preview="generateReferencePreview"
				v-model="format" />
	
			<control-publishers
				label="Label/Publisher Name"
				field-type="reference"
				:is-show-city-state="false" /> 

			<form-input-reference-wizard 
				label="URL or Platform"
				help-text="Only include the URL or the platform for the work (e.g., Spotify) if it can be accessed online."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="url" />
		
			<form-input-reference-wizard 
				label="Music Medium"
				help-text="Type the medium for how you accessed the music (e.g., CD, MP3)."
				@generate-reference-preview="generateReferencePreview"
				v-model="medium" />
		
		</section>
	</div>
</template>

<script>
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeMusic',
	mixins: [mixinReferenceTypeGenesis],
	methods:{
		calcContributors(){
			// set default values
			this.usedContributorTypes = [];

			// Author
			this.addContributorRole({
				addDefault: true,
				type: 'author',	// display as artist
			});

			// Group Author
			this.addContributorRole({
				type: 'groupAuthor', // display as groupArtist
			});

			if(this.isRefRulesEmpty) {
				// Load Fresh - Construct based on type and kind
				if(this.kind != 'original') {
					this.addContributorRole({
						type: 'performer',
					});

					this.addContributorRole({
						type: 'groupPerformer',
					});
				}
				
			} else {
				// Load Based on previously entered data in Step 3 (or an Edit)
				
				// Update Used Contributor Types
				this.usedContributorTypes = ['author', 'groupAuthor', 'groupPerformer', 'performer'];
			}//e:if:isRefRulesEmpty


			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		
		},//e:calcContributors
	},
	mounted(){
		if(this.kind === ''){
			this.kind = 'original';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
		if(this.type === ''){
			this.type = 'album';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
	},
}
</script>