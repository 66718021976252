<!--
- Publishers > Block
-- sortable block for a single publisher
-- 2024.11.22
-->

<template>
	<div class="mb-3">
		<div v-if="!isSingleOnly" class="form-group mb-1">
			<div class="d-flex">
				<div class="w-100 mb-2">
					&nbsp;
				</div>
				<div class="icon-row flex-shrink-1">
					<div class="d-flex flex-row-reverse">
						<a href="#" @click.prevent="sortPublisherBlock(false)" v-if="publisherIndex != ($store.state.referenceRules.formData.publishers[fieldType].length - 1)" title="Move Publisher Down">
							<span class="svg svg-sort bi-arrow-down" />
						</a>
						<a href="#" @click.prevent="sortPublisherBlock(true)" v-if="publisherIndex !== 0" title="Move Publisher Up">
							<span class="svg svg-sort bi-arrow-up" />
						</a>
						<a href="#" @click.prevent="removePublisherBlock" title="Remove Publisher">
							<span class="svg svg-delete bi-x-lg" />
						</a>
					</div>
				</div>
				
			</div><!--d-flex-->
		</div><!--form-group-->
		
		<publishers-input
			v-if="isShowName"
			label="Name"
			:is-show-city-state="isShowCityState"
			v-model="publisher_name"
			:is-focus-on-add="true" />

		<div v-if="isShowCityState">
			<publishers-input
				label="City"
				v-model="publisher_city" />

			<publishers-input
				v-if="isShowCityState"
				label="State"
				v-model="publisher_state" />
		</div>

	</div>
</template>

<script>
import config from '@/config';
import HelperGetReferenceFormatId from '@/helpers/get-reference-format-id';
import PublishersInput from '@/components/reference-rules-genesis/_PublishersInput';

export default {
	name: 'PublishersBlock',
	props:{
		isShowCityState: {
			type: Boolean
		},
		isSingleOnly: {	// case when mutil text has only a single value
			default: false,
			type: Boolean
		},
		publisherIndex: {
			type: Number
		},
		fieldType: {
			type: String,
		},
	},
	data() {
		return {
		}
	},
	computed:{
		isShowName(){
			// Turabian 9 Interview doesn't have a name, this becomes 'Location of Interview' in that case
			if(
				this.referenceFormatTypeId === config.enums.Format.Turabian9 && 
				this.$store.state.referenceRules.referenceTypeId === 23 && 
				this.$store.state.referenceRules.formData.type === 'personal'
			){
				return false;
			}

			return true;
		},
		publisher_city:{
			get(){
				return this.$store.state.referenceRules.formData.publishers[this.fieldType][this.publisherIndex].city;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_PUBLISHER_AT_INDEX', {
					publisherIndex: this.publisherIndex,
					fieldType: this.fieldType,
					key: 'city',
					value,
				});
			}
		},
		publisher_name:{
			get(){
				return this.$store.state.referenceRules.formData.publishers[this.fieldType][this.publisherIndex].name;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_PUBLISHER_AT_INDEX', {
					publisherIndex: this.publisherIndex,
					fieldType: this.fieldType,
					key: 'name',
					value,
				});
			}
		},
		publisher_state:{
			get(){
				return this.$store.state.referenceRules.formData.publishers[this.fieldType][this.publisherIndex].state;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_PUBLISHER_AT_INDEX', {
					publisherIndex: this.publisherIndex,
					fieldType: this.fieldType,
					key: 'state',
					value,
				});
			}
		},
		referenceFormatTypeId(){
			return HelperGetReferenceFormatId();
		},
	},
	methods: {
		removePublisherBlock(){
			this.$store.commit('referenceRules/REMOVE_PUBLISHER_AT_INDEX',{
				fieldType: this.fieldType,
				publisherIndex: this.publisherIndex,
			});
			this.$store.dispatch('referenceRules/generateReferencePreview');
		},
		sortPublisherBlock(isMoveUp){
			let oldIndex = (isMoveUp) ? (this.publisherIndex - 1) : (this.publisherIndex + 1);
			this.$store.commit('referenceRules/SORT_PUBLISHERS',{
				fieldType: this.fieldType,
				publisherIndex: this.publisherIndex,
				oldIndex,
			});
			this.$store.dispatch('referenceRules/generateReferencePreview');
		},
	},
	components: {
		PublishersInput
	}
}
</script>
