<!--
- Gray Literature
-- Reference Type Id: 4
-- 2023.10.18
-->

<template>
	<div>
		<reference-type-switcher
			v-if="isThirdPartyLookUp"
			original-reference-type-id="4" />

		<section class="mb-5">
			<h3>Format Options</h3>

			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input 
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Whole"
								value="whole"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Type_Whole">
								A standalone work: report, brief, codes, etc.
								<span 
									class="svg bi-info-circle has-tooltip" 
									data-bs-toggle="tooltip" 
									data-bs-placement="bottom" 
									role="tooltip"
									title="Use this type of reference if your work was created or published by itself.  This is more common than a work in a series." />
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input 
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Series"
								value="series"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Type_Series">
								A work that is part of a series
								<span 
									class="svg bi-info-circle has-tooltip" 
									data-bs-toggle="tooltip" 
									data-bs-placement="bottom" 
									role="tooltip"
									title="Use this reference type for citing a work that was published as part of a greater series of works." />
							</label>
						</div>
					</div>
				</div>
			</div>
			
		</section>

		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-textarea-reference-wizard 
				label="Title of the Work"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-bracket-help="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="workTitle" />
			
			<form-textarea-reference-wizard
				v-show="type == 'series'"
				label="Title of the Series"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="seriesTitle" />
						
			<form-input-reference-wizard
				label="Identifying Information"
				help-text="Include any additional information needed to clearly identify the work (e.g., Project No. 53421, Effective April 1, or Volume 4)."
				@generate-reference-preview="generateReferencePreview"
				v-model="description" />

			<form-input-reference-wizard 
				label="Format or Type"
				help-text="Include a brief description for works that aren't easily identified by their Title or Identifying Information (e.g., Report, Policy Brief)."
				@generate-reference-preview="generateReferencePreview"
				v-model="format" />

			<form-input-reference-wizard 
				label="Publication Year"
				help-text="Type the copyright date for this version of the text. Add the date the work was produced in Original Publication Year."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="publicationDate" />

			<control-publishers
				field-type="reference" /> 

			<control-toggle-select 
				v-show="type !== 'special'" 
				:label="toggleSelect.doiUrl.label"
				:is-common-use="toggleSelect.doiUrl.isCommonUse"
				:options="toggleSelect.doiUrl.options"
				default-option="url"
				group-name="doiUrl" />
		</section>
	</div>
</template>

<script>
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';
import ReferenceTypeSwitcher from '@/components/reference-rules-genesis/ReferenceTypeSwitcher';

export default {
	name: 'RefTypeGrayLiterature',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = [];

			this.addContributorRole({
				addDefault: true,
				type: 'groupAuthor',
			});

			this.addContributorRole({
				type: 'author',
			});

			this.addContributorRole({
				type: 'editor',
			});

			if(!this.isRefRulesEmpty) {
				// Load Based on previously entered data in Step 3 (or an Edit)
				
				// Update Used Contributor Types
				this.usedContributorTypes = ['author','groupAuthor','editor'];

			}//e:if:isRefRulesEmpty
			
			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},//e:calcContributors
	},
	mounted(){
		if(this.type === ''){
			this.type = 'whole';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
	},
	components:{
		ReferenceTypeSwitcher,
	},
}
</script>