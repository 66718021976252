var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "mb-5" }, [
      _c("h3", [_vm._v("Format Options")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [_vm._v("Choose a type")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Museum",
                  value: "museum",
                },
                domProps: { checked: _vm._q(_vm.type, "museum") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "museum"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(0),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Outside",
                  value: "outside",
                },
                domProps: { checked: _vm._q(_vm.type, "outside") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "outside"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(1),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          attrs: {
            label: "Artwork Title",
            "help-text": _vm.calcTitleHelpText,
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-bracket-help": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          key: "dateProductedElse",
          attrs: {
            label: "Date Produced",
            "help-text": _vm.calcDateProducedHelpText,
            "is-common-use":
              _vm.referenceFormatTypeId == _vm.$enums.Format.MLA9
                ? true
                : false,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.publicationDate,
            callback: function ($$v) {
              _vm.publicationDate = $$v
            },
            expression: "publicationDate",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Format / Medium",
            "help-text":
              'Enter the format or medium of the artwork. If needed, include the dimensions of the piece separated by a comma (Oil on canvas, 28 x 12").',
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.format,
            callback: function ($$v) {
              _vm.format = $$v
            },
            expression: "format",
          },
        }),
        _vm._v(" "),
        _vm.type === "museum"
          ? _c(
              "div",
              [
                _c("control-publishers", {
                  attrs: {
                    label: "Museum or Collection Name",
                    "help-text-prop":
                      "Capitalize the name exactly as it appears.",
                    "field-type": "reference",
                    "is-show-city-state": false,
                    "is-single-only": true,
                  },
                }),
                _vm._v(" "),
                _c("form-input-reference-wizard", {
                  attrs: {
                    label: "Artwork Location: City",
                    "help-text":
                      "Type the name of the city where the artwork is located.",
                    "is-common-use": true,
                  },
                  on: {
                    "generate-reference-preview": _vm.generateReferencePreview,
                  },
                  model: {
                    value: _vm.city,
                    callback: function ($$v) {
                      _vm.city = $$v
                    },
                    expression: "city",
                  },
                }),
                _vm._v(" "),
                _c("form-input-reference-wizard", {
                  attrs: {
                    label: "Artwork Location: State",
                    "help-text":
                      "Type the state's two letter abbreviation (e.g. TN). Don't include for foreign countries.",
                    "is-common-use": true,
                  },
                  on: {
                    "generate-reference-preview": _vm.generateReferencePreview,
                  },
                  model: {
                    value: _vm.state,
                    callback: function ($$v) {
                      _vm.state = $$v
                    },
                    expression: "state",
                  },
                }),
                _vm._v(" "),
                _c("form-input-reference-wizard", {
                  attrs: {
                    label: "Artwork Location: Country",
                    "help-text":
                      "Include the name of the country even if it is domestic (e.g. United States).",
                    "is-common-use": true,
                  },
                  on: {
                    "generate-reference-preview": _vm.generateReferencePreview,
                  },
                  model: {
                    value: _vm.country,
                    callback: function ($$v) {
                      _vm.country = $$v
                    },
                    expression: "country",
                  },
                }),
              ],
              1
            )
          : _vm.type === "outside"
          ? _c(
              "div",
              [
                _c("control-publishers", {
                  attrs: {
                    label: "Publisher/Venue Name",
                    "help-text-prop":
                      "Type the full name exactly as it appears.",
                    "field-type": "reference",
                    "is-show-city-state": true,
                    "is-single-only": true,
                  },
                }),
                _vm._v(" "),
                _c("form-input-reference-wizard", {
                  attrs: {
                    label: "URL",
                    "help-text":
                      "Type or paste the entire URL. You can use a URL shortener if it is too long (e.g., bit.ly).",
                    "is-common-use": true,
                  },
                  on: {
                    "generate-reference-preview": _vm.generateReferencePreview,
                  },
                  model: {
                    value: _vm.url,
                    callback: function ($$v) {
                      _vm.url = $$v
                    },
                    expression: "url",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-check-label", attrs: { for: "RefWiz_Type_Museum" } },
      [
        _vm._v("\n\t\t\t\t\t\t\tArtwork in a museum\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            title:
              "This reference type is for citing a piece of art that is displayed in a museum, gallery, or their corresponding websites.",
            role: "tooltip",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "RefWiz_Type_Outside" },
      },
      [
        _vm._v(
          "\n\t\t\t\t\t\t\tArtwork viewed outside a museum\n\t\t\t\t\t\t\t"
        ),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            title:
              "If the artwork is displayed outside a museum (like a public park or government building), use this reference type.",
            role: "tooltip",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }