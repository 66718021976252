<!--
- Podcasts Radio
-- Reference Type Id: 34
-- 2024.04.15
-->

<template>
	<div>
		<section class="mb-5">
			<h3>Format Options</h3>
			
			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Episode"
								value="episode"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Type_Episode">
								A single podcast or radio episode
								<span
									class="svg bi-info-circle has-tooltip"
									data-bs-toggle="tooltip"
									data-bs-placement="bottom"
									role="tooltip"
									title="This is used for citing specific episodes from a series or show." />
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Series"
								value="series"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Type_Series">
								An entire podcast or radio series
								<span
									class="svg bi-info-circle has-tooltip"
									data-bs-toggle="tooltip"
									data-bs-placement="bottom"
									data-bs-html="true"
									role="tooltip"
									title="Use then when you aren&lsquo;t referring to a single episode, but a series/show in general." />
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Interview"
								value="interview"
								v-model="type"
								@change="typeChange" /> 
							<label class="form-check-label" for="RefWiz_Type_Interview">
								A radio interview in a digital archive
								<span
									class="svg bi-info-circle has-tooltip"
									data-bs-toggle="tooltip"
									data-bs-placement="bottom"
									role="tooltip"
									title="Use this for archived radio interviews only.  If the interview is not archived, cite it as a single podcast or radio episode." />
							</label>
						</div>
					</div>
				</div>
			</div>

			<!-- Kind -->
			<div class="form-group mb-0 py-2" v-show="type != 'interview'">
				<label>What kind of podcast or radio episode are you referencing?</label>
				<select class="form-select" v-model="kind" @change="calcContributors" aria-label="What kind of podcast or radio episode are you referencing?">
					<option value="audio">
						An audio podcast
					</option>
					<option value="video">
						A video podcast
						</option>
					<option value="radio">
						A radio broadcast
					</option>
				</select>
			</div>
		</section>

		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>
			
			<form-textarea-reference-wizard
				v-show="type != 'interview'"
				label="Series Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />
			
			<form-textarea-reference-wizard
				v-show="type == 'interview'"
				label="Interview Title"
				help-text='Capitalize all major words of the title and subtitle, including proper nouns, e.g. "John Doe Recalls His Encounter with Bigfoot"'
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />

			<form-textarea-reference-wizard
				v-show="type == 'episode'"
				label="Episode Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="episodeTitle" />
			
			<form-input-reference-wizard
				v-show="type == 'series'"
				label="Years the Series Aired"
				help-text="Type the years that the series first aired. If the show is still being produced, the second year should be &quot;present&quot;."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="publicationDate" />
			
			<form-datepicker
				v-show="type != 'series'"
				id="RefWiz_PublicationDate" 
				label="Episode Date"
				help-text="Enter the date the episode first aired or was published."
				:is-common-use="true"
				:is-all-common-use="(type === 'episode')"
				:paper-format="0"
				@date-set="dateSetPublication"
				@date-clear="dateClearPublication" />
			
			<form-input-reference-wizard
				v-show="type == 'episode'"
				label="Episode Number"
				help-text="Type the number for the episode (e.g., 114)."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="episodeNumber" />
			
			<form-time-split-reference-wizard
				v-if="type == 'episode'"	
				engine-value="length"
				label="Recording Length"
				help-text="Enter the full length of the program recording."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview" />

			<control-publishers
				v-show="type != 'interview'"
				label="Production Company"
				button-label="producer"
				field-type="reference"
				:is-show-city-state="false" />

			<form-input-reference-wizard
				v-show="type != 'interview'"
				label="Platform"
				help-text="Enter the platform if it is different from the Production Company."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="platform" />

			<form-input-reference-wizard
				v-show="type == 'interview'"
				label="Archive Name"
				@generate-reference-preview="generateReferencePreview"
				v-model="archiveName" />

			<control-publishers
				v-show="type === 'interview'"
				label="Archive Institution"
				button-label="institution"
				field-type="reference"
				:help-text="calcHelpTextArchiveInstitution"
				:is-show-city-state="false" />

			<form-input-reference-wizard
				label="URL"
				help-text="Type or paste the entire URL. You can use a URL shortener if it is too long (e.g., bit.ly)."
				@generate-reference-preview="generateReferencePreview"
				v-model="url" />

		</section>
	</div>
</template>

<script>
import config from '@/config';
import FormTimeSplitReferenceWizard from '@/components/reference-rules-genesis/_TimeSplitReferenceWizard';
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypePodcastsRadio',
	mixins: [mixinReferenceTypeGenesis],
	computed:{
		calcHelpTextArchiveInstitution(){
			if(this.referenceFormatTypeId === config.enums.Format.MLA9){
				return "Include the department, institution, and location information separated by a comma if possible.";
			} else {
				return "Don't include business titles (e.g., Inc., Co., Ltd., etc.).";
			}
		}, 
	},
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = [];

			if(this.isRefRulesEmpty) {
				// Load Fresh - Construct based on type and kind
				if(this.type == 'episode') {
					this.addContributorRole({
						addDefault: true,
						type: 'host',
					});
				} else if(this.type == 'interview') {
					this.addContributorRole({
						addDefault: true,
						type: 'interview',
					});
				} else if(this.type == 'series') {
					this.addContributorRole({
						addDefault: true,
						type: 'host',
					});

					this.addContributorRole({
						type: 'executiveProducer',
					});
				}
				
			} else {
				// Load Based on previously entered data in Step 3 (or an Edit)
				// Update Used Contributor Types
				if(this.type == 'episode') {
					this.addContributorRole({
						type: 'host',
					});
					this.removeEmptyContributorRole({
						type: 'interview',
					});
				} else if(this.type == 'interview') {
					this.addContributorRole({
						type: 'host',
					});
					this.addContributorRole({
						addDefault: true,
						type: 'interview',
					});
					this.removeEmptyContributorRole({
						type: 'executiveProducer',
					});
				} else if(this.type == 'series') {
					this.addContributorRole({
						addDefault: true,
						type: 'host',
					});
					this.addContributorRole({
						type: 'executiveProducer',
					});
					this.removeEmptyContributorRole({
						type: 'interview',
					});
				}
				
			}//e:if:isRefRulesEmpty

			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},//e:calcContributors
		
		typeChange(){
			// changing radio button 'type' should reselect the first option in the 'kind' dropdown
			if(this.type === 'interview'){
				this.kind = '';
			} else {
				this.kind = 'audio';
			}
			this.publicationDate = ''; // clear publication date - it could be year, full date, or not appear at all
			this.$nextTick(()=>{
				this.calcContributors();
			});
		},
	},
	mounted(){
		if(this.kind === ''){
			this.kind = 'audio';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
		if(this.type === ''){
			this.type = 'episode';
			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
	},
	components: {
		FormTimeSplitReferenceWizard
	}
}
</script>