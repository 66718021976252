/*
- Mixin for all Genesis Engine Reference Types
-- Website, Book, etc
-- 2024.11.22
*/

import _cloneDeep from 'lodash/cloneDeep';
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import AS_LoadDatepicker from '@/services/app/loadDatepicker';
import config from '@/config';
import ControlContributor from "./_Contributor";
import ControlPublishers from '@/components/reference-rules-genesis/_Publishers';
import ControlToggleSelect from '@/components/reference-rules-genesis/_ToggleSelect';
import FormDatepicker from '@/components/form/Datepicker';
import FormInputReferenceWizard from '@/components/reference-rules-genesis/_InputReferenceWizard';
import FormTextareaReferenceWizard from '@/components/reference-rules-genesis/_TextareaReferenceWizard';
import HelperDateIsReferenceWizardValid from '@/helpers/date-is-reference-wizard-valid.js';
import HelperGetReferenceFormatId from '@/helpers/get-reference-format-id';
import HelperRemoveHtmlEntities from '@/helpers/remove-html-entities';
import Tooltip from 'bootstrap/js/dist/tooltip';
import { v4 as uuidv4 } from 'uuid';

export default {
	props:{
		isThirdPartyLookUp:{
			default: false,
			type: Boolean,
		},
		referenceRulesProp: {
			default: () => [],
			type: Array|Object,
		},
		referenceRulesString: {
			default: '',
			type: String,
		}
	},
	data() {
		return {
			usedContributorTypes: [], 	// sets in calcContributors()
		}
	},
	computed:{
		albumTitle:{
			get(){
				return this.$store.state.referenceRules.formData.albumTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'albumTitle',
					value
				});
			},
		},
		amendment:{
			get(){
				return this.$store.state.referenceRules.formData.amendment;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'amendment',
					value
				});
			},
		},
		anthologyTitle:{
			get(){
				return this.$store.state.referenceRules.formData.anthologyTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'anthologyTitle',
					value
				});
			},
		},

		appealCircuit:{
			get(){
				return this.$store.state.referenceRules.formData.appealCircuit;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'appealCircuit',
					value
				});
			},
		},
		appealDate:{
			get(){
				return this.$store.state.referenceRules.formData.appealDate;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'appealDate',
					value
				});
			},
		},
		appealPageNumber:{
			get(){
				return this.$store.state.referenceRules.formData.appealPageNumber;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'appealPageNumber',
					value
				});
			},
		},
		appealSeries:{
			get(){
				return this.$store.state.referenceRules.formData.appealSeries;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'appealSeries',
					value
				});
			},
		},
		appealVolume:{
			get(){
				return this.$store.state.referenceRules.formData.appealVolume;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'appealVolume',
					value
				});
			},
		},
		archiveName:{
			get(){
				return this.$store.state.referenceRules.formData.archiveName;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'archiveName',
					value
				});
			},
		},
		article:{
			get(){
				return this.$store.state.referenceRules.formData.article;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'article',
					value
				});
			},
		},
		articleNumber:{
			get(){
				return this.$store.state.referenceRules.formData.articleNumber;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'articleNumber',
					value
				});
			},
		},
		articleTitle:{
			get(){
				return HelperRemoveHtmlEntities(this.$store.state.referenceRules.formData.articleTitle);
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'articleTitle',
					value
				});
			},
		},
		attachment:{
			get(){
				return this.$store.state.referenceRules.formData.attachment;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'attachment',
					value
				});
			},
		},
		billNumber:{
			get(){
				return this.$store.state.referenceRules.formData.billNumber;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'billNumber',
					value
				});
			},
		},
		blog:{
			get(){
				return this.$store.state.referenceRules.formData.blog;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'blog',
					value
				});
			},
		},
		blogTitle:{
			get(){
				return this.$store.state.referenceRules.formData.blogTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'blogTitle',
					value
				});
			},
		},
		bookTitle:{
			get(){
				return HelperRemoveHtmlEntities(this.$store.state.referenceRules.formData.bookTitle);
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'bookTitle',
					value
				});
			},
		},
		calcTitleHelpText(){
			return `Capitalize the first word of the title, subtitle, and all proper nouns. If there isn't a title, add a description inside brackets.`;
		},
		channel:{
			get(){
				return HelperRemoveHtmlEntities(this.$store.state.referenceRules.formData.channel);
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'channel',
					value
				});
			},
		},
		chapterTitle:{
			get(){
				return HelperRemoveHtmlEntities(this.$store.state.referenceRules.formData.chapterTitle);
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'chapterTitle',
					value
				});
			},
		},
		charterArticle:{
			get(){
				return this.$store.state.referenceRules.formData.charterArticle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'charterArticle',
					value
				});
			},
		},
		circuit:{
			get(){
				return this.$store.state.referenceRules.formData.circuit;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'circuit',
					value
				});
			},
		},
		citation:{
			get(){
				return this.$store.state.referenceRules.formData.citation;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'citation',
					value
				});
			},
		},
		city:{
			get(){
				return this.$store.state.referenceRules.formData.city;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'city',
					value
				});
			},
		},
		committeeName:{
			get(){
				return this.$store.state.referenceRules.formData.committeeName;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'committeeName',
					value
				});
			},
		},
		conference:{
			get(){
				return this.$store.state.referenceRules.formData.conference;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'conference',
					value
				});
			},
		},
		copyrightYear:{
			get(){
				return this.$store.state.referenceRules.formData.copyrightYear;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'copyrightYear',
					value
				});
			},
		},
		content:{
			get(){
				return this.$store.state.referenceRules.formData.content;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'content',
					value
				});
			},
		},
		contributors:{
			get(){
				return this.$store.state.referenceRules.formData.contributors;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'contributors',
					value
				});
			},
		},
		country:{
			get(){
				return this.$store.state.referenceRules.formData.country;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'country',
					value
				});
			},
		},
		court:{
			get(){
				return this.$store.state.referenceRules.formData.court;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'court',
					value
				});
			},
		},
		databaseTitle:{
			get(){
				return this.$store.state.referenceRules.formData.databaseTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'databaseTitle',
					value
				});
			},
		},
		description:{
			get(){
				return this.$store.state.referenceRules.formData.description;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'description',
					value
				});
			},
		},
		database:{
			get(){
				return this.$store.state.referenceRules.formData.database;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'database',
					value
				});
			},
		},
		district:{
			get(){
				return this.$store.state.referenceRules.formData.district;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'district',
					value
				});
			},
		},
		doi:{
			get(){
				return this.$store.state.referenceRules.formData.doi;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'doi',
					value
				});
			},
		},
		edition:{
			get(){
				return this.$store.state.referenceRules.formData.edition;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'edition',
					value
				});
			},
		},
		entry:{
			get(){
				return this.$store.state.referenceRules.formData.entry;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'entry',
					value
				});
			},
		},
		entryTitle:{
			get(){
				return this.$store.state.referenceRules.formData.entryTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'entryTitle',
					value
				});
			},
		},
		episodeNumber:{
			get(){
				return this.$store.state.referenceRules.formData.episodeNumber;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'episodeNumber',
					value
				});
			},
		},
		episodeTitle:{
			get(){
				return this.$store.state.referenceRules.formData.episodeTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'episodeTitle',
					value
				});
			},
		},
		filedDate:{
			get(){
				return this.$store.state.referenceRules.formData.filedDate;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'filedDate',
					value
				});
			},
		},
		format:{
			get(){
				return this.$store.state.referenceRules.formData.format;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'format',
					value
				});
			},
		},
		institution:{
			get(){
				return this.$store.state.referenceRules.formData.institution;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'institution',
					value
				});
			},
		},
		issue:{
			get(){
				return this.$store.state.referenceRules.formData.issue;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'issue',
					value
				});
			},
		},
		issueTitle:{
			get(){
				return this.$store.state.referenceRules.formData.issueTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'issueTitle',
					value
				});
			},
		},
		journalTitle:{
			get(){
				return HelperRemoveHtmlEntities(this.$store.state.referenceRules.formData.journalTitle);
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'journalTitle',
					value
				});
			},
		},
		kind:{
			get(){
				return this.$store.state.referenceRules.formData.kind;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'kind',
					value
				});
			},
		},
		length:{
			get(){
				return this.$store.state.referenceRules.formData.length;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'length',
					value
				});
			},
		},
		location:{
			get(){
				return this.$store.state.referenceRules.formData.location;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'location',
					value
				});
			},
		},
		magazineTitle:{
			get(){
				return this.$store.state.referenceRules.formData.magazineTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'magazineTitle',
					value
				});
			},
		},
		medium:{
			get(){
				return this.$store.state.referenceRules.formData.medium;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'medium',
					value
				});
			},
		},
		model:{
			get(){
				return this.$store.state.referenceRules.formData.model;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'model',
					value
				});
			},
		},
		newspaperTitle:{
			get(){
				return this.$store.state.referenceRules.formData.newspaperTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'newspaperTitle',
					value
				});
			},
		},
		number:{
			get(){
				return this.$store.state.referenceRules.formData.number;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'number',
					value
				});
			},
		},
		orderNumber:{
			get(){
				return this.$store.state.referenceRules.formData.orderNumber;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'orderNumber',
					value
				});
			},
		},
		originalArticleTitle:{
			get(){
				return this.$store.state.referenceRules.formData.originalArticleTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'originalArticleTitle',
					value
				});
			},
		},
		originalBookTitle:{
			get(){
				return this.$store.state.referenceRules.formData.originalBookTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'originalBookTitle',
					value
				});
			},
		},
		originalEdition:{
			get(){
				return this.$store.state.referenceRules.formData.originalEdition;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'originalEdition',
					value
				});
			},
		},
		originalIssue:{
			get(){
				return this.$store.state.referenceRules.formData.originalIssue;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'originalIssue',
					value
				});
			},
		},
		originalJournalTitle:{
			get(){
				return this.$store.state.referenceRules.formData.originalJournalTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'originalJournalTitle',
					value
				});
			},
		},
		originalMagazineTitle:{
			get(){
				return this.$store.state.referenceRules.formData.originalMagazineTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'originalMagazineTitle',
					value
				});
			},
		},
		originalNewspaperTitle:{
			get(){
				return this.$store.state.referenceRules.formData.originalNewspaperTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'originalNewspaperTitle',
					value
				});
			},
		},
		originalPublicationDate:{
			get(){
				return this.$store.state.referenceRules.formData.originalPublicationDate;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'originalPublicationDate',
					value
				});
			},
		},
		originalReferencePages:{
			get(){
				return this.$store.state.referenceRules.formData.originalReferencePages;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'originalReferencePages',
					value
				});
			},
		},
		originalVolume:{
			get(){
				return this.$store.state.referenceRules.formData.originalVolume;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'originalVolume',
					value
				});
			},
		},
		pageNumber:{
			get(){
				return this.$store.state.referenceRules.formData.pageNumber;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'pageNumber',
					value
				});
			},			
		},
		pageNumbers:{
			get(){
				return this.$store.state.referenceRules.formData.pageNumbers;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'pageNumbers',
					value
				});
			},
		},
		pageTitle:{
			get(){
				return HelperRemoveHtmlEntities(this.$store.state.referenceRules.formData.pageTitle);
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'pageTitle',
					value
				});
			},
		},
		paragraph:{
			get(){
				return this.$store.state.referenceRules.formData.paragraph;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'paragraph',
					value
				});
			},
		},
		parties:{
			get(){
				return this.$store.state.referenceRules.formData.parties;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'parties',
					value
				});
			},
		},
		periodical:{
			get(){
				return this.$store.state.referenceRules.formData.periodical;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'periodical',
					value
				});
			},
		},
		platform:{
			get(){
				return this.$store.state.referenceRules.formData.platform;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'platform',
					value
				});
			},
		},
		proposedVolume:{
			get(){
				return this.$store.state.referenceRules.formData.proposedVolume;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'proposedVolume',
					value
				});
			},
		},
		publicationDate:{
			get(){
				return this.$store.state.referenceRules.formData.publicationDate;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'publicationDate',
					value
				});
			},
		},
		record:{ 
			get(){
				return this.$store.state.referenceRules.formData.record;
			},
			set(value){
				// Book
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'record',
					value
				});
			},
		},
		recordDate:{
			get(){
				return this.$store.state.referenceRules.formData.recordDate;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'recordDate',
					value
				});
			},
		},
		referencePages:{ 
			get(){
				return this.$store.state.referenceRules.formData.referencePages;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'referencePages',
					value
				});
			},
		},
		referenceTitle:{ 
			get(){
				return this.$store.state.referenceRules.formData.referenceTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'referenceTitle',
					value
				});
			},
		},
		repealDate:{ 
			get(){
				return this.$store.state.referenceRules.formData.repealDate;
			},
			set(value){
				// Book
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'repealDate',
					value
				});
			},
		},
		reportNumber:{ 
			get(){
				return this.$store.state.referenceRules.formData.reportNumber;
			},
			set(value){
				// Book
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'reportNumber',
					value
				});
			},
		},
		retractionDate:{
			get(){
				return this.$store.state.referenceRules.formData.retractionDate;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'retractionDate',
					value
				});
			},
		},
		retractionIssue:{
			get(){
				return this.$store.state.referenceRules.formData.retractionIssue;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'retractionIssue',
					value
				});
			},
		},
		retractionPages:{
			get(){
				return this.$store.state.referenceRules.formData.retractionPages;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'retractionPages',
					value
				});
			},
		},
		retractionVolume:{
			get(){
				return this.$store.state.referenceRules.formData.retractionVolume;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'retractionVolume',
					value
				});
			},
		},
		retrievalDate:{
			get(){
				return this.$store.state.referenceRules.formData.retrievalDate;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'retrievalDate',
					value
				});
			},
		},
		reviewedTitle:{
			get(){
				return this.$store.state.referenceRules.formData.reviewedTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'reviewedTitle',
					value
				});
			},
		},
		revisedDate:{
			get(){
				return this.$store.state.referenceRules.formData.revisedDate;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'revisedDate',
					value
				});
			},
		},
		runtime:{
			get(){
				return this.$store.state.referenceRules.formData.runtime;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'runtime',
					value
				});
			},
		},
		section:{
			get(){
				return this.$store.state.referenceRules.formData.section;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'section',
					value
				});
			},
		},
		sectionTitle:{
			get(){
				return this.$store.state.referenceRules.formData.sectionTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'sectionTitle',
					value
				});
			},
		},
		seasonNumber:{
			get(){
				return this.$store.state.referenceRules.formData.seasonNumber;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'seasonNumber',
					value
				});
			},
		},
		series:{
			get(){
				return this.$store.state.referenceRules.formData.series;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'series',
					value
				});
			},
		},
		seriesTitle:{
			get(){
				return this.$store.state.referenceRules.formData.seriesTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'seriesTitle',
					value
				});
			},
		},
		session:{
			get(){
				return this.$store.state.referenceRules.formData.session;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'session',
					value
				});
			},
		},
		sessionPart:{
			get(){
				return this.$store.state.referenceRules.formData.sessionPart;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'sessionPart',
					value
				});
			},
		},
		shortTitle:{
			get(){
				return this.$store.state.referenceRules.formData.shortTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'shortTitle',
					value
				});
			},
		},
		songTitle:{
			get(){
				return this.$store.state.referenceRules.formData.songTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'songTitle',
					value
				});
			},
		},
		source:{
			get(){
				return this.$store.state.referenceRules.formData.source;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'source',
					value
				});
			},
		},
		sourceLocation:{
			get(){
				return this.$store.state.referenceRules.formData.sourceLocation;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'sourceLocation',
					value
				});
			},
		},
		sourceTitle:{
			get(){
				return this.$store.state.referenceRules.formData.sourceTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'sourceTitle',
					value
				});
			},
		},
		sponsor:{
			get(){
				return this.$store.state.referenceRules.formData.sponsor;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'sponsor',
					value
				});
			},
		},
		state:{
			get(){
				return this.$store.state.referenceRules.formData.state;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'state',
					value
				});
			},
		},
		symposiumTitle:{
			get(){
				return HelperRemoveHtmlEntities(this.$store.state.referenceRules.formData.symposiumTitle);
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'symposiumTitle',
					value
				});
			},
		},
		toggleSelect:{
			get(){
				return this.$store.state.referenceRules.formData.toggleSelect;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'toggleSelect',
					value
				});
			},
		},
		time:{
			get(){
				return this.$store.state.referenceRules.formData.time;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'time',
					value
				});
			},
		},
		title:{
			get(){
				return this.$store.state.referenceRules.formData.title;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'title',
					value
				});
			},
		},
		translatedAnthologyTitle:{
			get(){
				return this.$store.state.referenceRules.formData.translatedAnthologyTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'translatedAnthologyTitle',
					value
				});
			},
		},
		translatedArticleTitle:{
			get(){
				return this.$store.state.referenceRules.formData.translatedArticleTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'translatedArticleTitle',
					value
				});
			},
		},
		translatedEntryTitle:{
			get(){
				return this.$store.state.referenceRules.formData.translatedEntryTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'translatedEntryTitle',
					value
				});
			},
		},
		translatedChapterTitle:{
			get(){
				return this.$store.state.referenceRules.formData.translatedChapterTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'translatedChapterTitle',
					value
				});
			},
		},
		translatedIssueTitle:{
			get(){
				return this.$store.state.referenceRules.formData.translatedIssueTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'translatedIssueTitle',
					value
				});
			},
		},
		translatedNewspaperTitle:{
			get(){
				return this.$store.state.referenceRules.formData.translatedNewspaperTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'translatedNewspaperTitle',
					value
				});
			},
		},
		translatedTitle:{
			get(){
				return this.$store.state.referenceRules.formData.translatedTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'translatedTitle',
					value
				});
			},
		},
		translatedVolumeTitle:{
			get(){
				return this.$store.state.referenceRules.formData.translatedVolumeTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'translatedVolumeTitle',
					value
				});
			},
		},
		type:{
			get(){
				return this.$store.state.referenceRules.formData.type;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'type',
					value
				});
			},
		},
		url:{
			get(){
				return this.$store.state.referenceRules.formData.url;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'url',
					value
				});
			},
		},
		version:{
			get(){
				return this.$store.state.referenceRules.formData.version;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'version',
					value
				});
			},
		},
		volume:{
			get(){
				return this.$store.state.referenceRules.formData.volume;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'volume',
					value
				});
			},
		},
		volumeTitle:{
			get(){
				return this.$store.state.referenceRules.formData.volumeTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'volumeTitle',
					value
				});
			},
		},
		website:{
			get(){
				return this.$store.state.referenceRules.formData.website;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'website',
					value
				});
			},
		},
		websiteTitle:{
			get(){
				return this.$store.state.referenceRules.formData.websiteTitle;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'websiteTitle',
					value
				});
			},
		},
		workTitle:{
			get(){
				return HelperRemoveHtmlEntities(this.$store.state.referenceRules.formData.workTitle);
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'workTitle',
					value
				});
			},
		},
		workType:{
			get(){
				return this.$store.state.referenceRules.formData.workType;
			},
			set(value){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'workType',
					value
				});
			},
		},
		
		editReference(){
			if(config.platformId === config.enums.Platform.ONLINE){
				return this.$store.state.referenceEdit.openObject;
			} else if(config.platformId === config.enums.Platform.ADD_IN){
				return this.$store.state.references.editReference;
			}
			// add Chrome Ext
			return;
		},
		
		isRefRulesEmpty(){
			// reference rules could be empty or have an empty 'data' property
			if(_isEmpty(this.referenceRules)){
				return true;
			}
			if(_has(this.referenceRules, 'data') && _isEmpty(this.referenceRules.data)){
				return true;
			}
			return false;
		},

		referenceFormatTypeId(){
			return HelperGetReferenceFormatId();
		},

		referenceRules(){
			if(_isEmpty(this.$store.state.referenceRules.rulesObjectData)){
				if(this.referenceRulesString === '') {
					// string is empty
					if(_isEmpty(this.referenceRulesProp)){
						// Probably creating step 3 back to 2 (or it's first time Create Step 2 and the empty array wont' matter)

						// what do i do if this is empty?
						return {
							'data': null,
						};
						
					} else {
						// Use the Reference Rules Prop (Passed From Add From Library > Convert - or 3PLU)
						return {
							'data': this.referenceRulesProp
						}
					}
				
				} else {
					// Use the Reference Rules String (passed from Edit)
					return {
						'data': JSON.parse(this.referenceRulesString)
					}
				}

			} else {
				return {
					'data': this.$store.state.referenceRules.rulesObjectData,
					'values': this.$store.state.referenceRules.rulesObjectValues,
				};
			}
		},//e:referenceRules

		showTranslatorFields(){
			return this.$store.state.referenceRules.isTranslator;
		},
	},
	methods:{
		// called form calcContributors in each reference type
		addContributorRole($opts){
			// look for $opts.type type in _contributors
			let contributorsForThisType = _cloneDeep(this.$store.state.referenceRules.formData.contributors.filter((contributor)=>{
				return contributor.type === $opts.type;
			}));

			if(contributorsForThisType.length > 0){
				// data already set
				// contributorsForThisType.forEach((contributor)=>{
				// 	this.$store.commit('referenceRules/PUSH_TO_CONTRIBUTORS', contributor);
				// });
			} else {
				// no data set - push an empty one
				if($opts.addDefault){
					this.$store.commit('referenceRules/PUSH_TO_CONTRIBUTORS', {
						abbreviation:'',
						firstName:'',
						groupName:'',
						lastName:'',
						middleName:'',
						name: '',
						prefix: '',
						suffix:'',
						type: $opts.type,
						id: uuidv4().toUpperCase(),
					});
				}
			}
			if(!this.usedContributorTypes.includes($opts.type)){
				this.usedContributorTypes.push($opts.type);
			}
			// console.log('this.usedContributorTypes');
			// console.log(this.usedContributorTypes);
		},//e:addContributorRole

		dateClearPublication(){
			this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
				key: 'publicationDate',
				value: ''
			});
			this.generateReferencePreview();
		},

		dateSetPublication(newDate){
			if(HelperDateIsReferenceWizardValid(newDate)){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'publicationDate',
					value: newDate
				});
				this.generateReferencePreview();
			}
		},
		
		// fires after a selection or blur from all form element inside a reference (think of it like it also has to update the reference form data)
		generateReferencePreview(){
			// don't generate a preview if converting
			if(config.platformId === config.enums.Platform.ONLINE){
				if(this.$route.name === 'EditorConvertReferenceStep1' || this.$route.name === 'PaperTabResearchConvertReferenceStep1'){
					return false;
				}
			} else if(config.platformId === config.enums.Platform.ADD_IN){
				if(this.$route.path === '/reference-convert-apa7-step-1'){
					return false;
				}
			}
			// add Chrome Ext
		
			this.$store.dispatch('referenceRules/generateReferencePreview').then((returnData)=>{
				// console.log('returnData');
				// console.log(returnData);
			});
		},//e:generateReferencePreview
		
		// called from calcContributors in each reference type
		removeEmptyContributorRole($opts){
			// look for $opts.type type in _contributors
			let contributorsForThisType = _cloneDeep(this.$store.state.referenceRules.formData.contributors.filter((contributor)=>{
				return contributor.type === $opts.type;
			}));

			if(contributorsForThisType.length > 0){
				// there is a contributor for this type

				// is it blank?
				_forEach(contributorsForThisType, (contributorData)=>{
					if(contributorData.firstName === '' && contributorData.lastName === '' && contributorData.groupName === '' && contributorData.name === '') {
						this.$store.commit('referenceRules/REMOVE_CONTRIBUTOR_BY_ID', contributorData.id);
					}
				});
			}
		},//e:removeEmptyContributorRole
		
		sortContributorTypes(){
			let sortOrder = [
				'author',
				'userName',
				'groupAuthor',
				'groupPerformer',
				'performer',
				'host',
				'interview',
				'executiveProducer',
				'editor',
				'illustrator',
				'narrator',
				'translator',
				'chair',
				'originalAuthor',
				'executiveDirector','director', 'executiveProducer', 'writer', 'writerDirector', 'executiveDirectorProducer', 'executiveProducer',
				'reviewAuthor','reviewEditor', 'reviewDirector', 'reviewWriter','reviewWriterDirector', 'reviewArtist', 'reviewGroupArtist', 'reviewComposer',
				'custom',
			];

			this.usedContributorTypes.sort((a, b)=>{
				return sortOrder.indexOf(a) - sortOrder.indexOf(b);
			});

		},//e:sortContributorTypes
	},
	mounted(){
		this.$store.commit('referenceRules/SET_IS_TRANSLATOR', false);
		
		this.$nextTick(()=>{
			this.calcContributors();

			if(!this.isRefRulesEmpty){
				// reference rules are NOT empty

				let isDoiUrlToggleSelectSet = false;
			
				_forEach(this.referenceRules.data, (value,key)=>{	
					switch(key){
						case 'articleNumber':
						case 'referencePages':
							if(this.$store.state.referenceRules.referenceTypeId === 2 || this.$store.state.referenceRules.referenceTypeId === 29){
								// Book || Newspaper
								this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
									key,
									value
								});
							} else {
								// Toggle Switchers (Page Numbers / Article Number)
								if(value === ''){
									this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_CLEAR_UNSELECTED_OPTION', {
										groupName: 'pagesArticle',
										unselectedOptionName: key
									});
								} else {
									this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_SELECTED_OPTION', {
										groupName: 'pagesArticle',
										selectedOption: key,
									});
									this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_AT_GROUP', {
										groupName: 'pagesArticle',
										value: value,
									});
								}
							}
							break;
						case 'citation':
							// US State Court Cases only use this
							this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
								key: 'citation',
								value: value,
							});
							break;
						case 'contributors':
							// Contributor Only (value is an array of contributor block objects)
							var _contributors = [];

							_forEach(value, (contributorBlock, contributorBlockIndex)=>{
								// clone to prevent a Vuex error ('contributorBlock' is linked as a child to the Vuex referenceRules entry)
								_forEach(contributorBlock, (contributorValue, contributorKey)=>{
									// console.log(contributorKey);
									// console.log(contributorValue);
									if(!_has(_contributors, contributorBlockIndex)){
										_contributors[contributorBlockIndex] = {};
									}
									_contributors[contributorBlockIndex][contributorKey] = contributorValue;
								
								});//e:forEach:contributorBlock

								this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
									key: 'contributors',
									value: _contributors
								});
							
							});//e:forEach:value
							break;
						case 'doi':
						case 'url':
							// some reference data has url as a string instead of a toggleSelect value
							if(
								this.$store.state.referenceRules.referenceTypeId === 3 || // Website
								this.$store.state.referenceRules.referenceTypeId === 8 ||	// Apparatus
								this.$store.state.referenceRules.referenceTypeId === 9 ||	// Artwork
								this.$store.state.referenceRules.referenceTypeId === 10 ||	// Bills & Resolutions
								this.$store.state.referenceRules.referenceTypeId === 11 ||	// Blog
								this.$store.state.referenceRules.referenceTypeId === 12 ||	// Charts, Infographics, & Maps
								this.$store.state.referenceRules.referenceTypeId === 16 ||	// Dissertations & Theses
								this.$store.state.referenceRules.referenceTypeId === 17 ||	// Executive Orders
								this.$store.state.referenceRules.referenceTypeId === 18 ||	// Federal Testimony
								this.$store.state.referenceRules.referenceTypeId === 19 || 	// Film & Movies
								this.$store.state.referenceRules.referenceTypeId === 20 ||	// Government Reports
								this.$store.state.referenceRules.referenceTypeId === 24 ||	// International Conventions & Treaties
								this.$store.state.referenceRules.referenceTypeId === 25 ||	// Lectures
								this.$store.state.referenceRules.referenceTypeId === 28 ||	// Music
								this.$store.state.referenceRules.referenceTypeId === 30 ||	// Online Comment
								this.$store.state.referenceRules.referenceTypeId === 32 ||	// Patent
								this.$store.state.referenceRules.referenceTypeId === 34 ||	// Podcasts Radio
								this.$store.state.referenceRules.referenceTypeId === 38 ||	// Reviews
								this.$store.state.referenceRules.referenceTypeId === 39 ||	// SocialMedia
								this.$store.state.referenceRules.referenceTypeId === 40 ||	// Software
								this.$store.state.referenceRules.referenceTypeId === 41 ||	// Speech
								this.$store.state.referenceRules.referenceTypeId === 42 ||	// Statutes
								this.$store.state.referenceRules.referenceTypeId === 44 ||	// Tests & Scales
								this.$store.state.referenceRules.referenceTypeId === 45 ||	// US Federal Court
								this.$store.state.referenceRules.referenceTypeId === 46 ||	// State Court
								this.$store.state.referenceRules.referenceTypeId === 47 	// Videos
							){
								// String 
								this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
									key,
									value
								});
								
							} else {
								// Toggle Switchers (DOI/URL)
								if(value === ''){
									this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_CLEAR_UNSELECTED_OPTION', {
										groupName: 'doiUrl',
										unselectedOptionName: key
									});

								} else {
									// if there is a DOI & URL, default to the DOI in the dropdown
									if(!isDoiUrlToggleSelectSet){
										this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_SELECTED_OPTION', {
											groupName: 'doiUrl',
											selectedOption: key,
										});
										this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_AT_GROUP', {
											groupName: 'doiUrl',
											value: value,
										});
										isDoiUrlToggleSelectSet = true;
									}
									
								}
							}
							break;
						case 'institution':
							if(typeof value === 'string'){
								// 16 - Dissertations have Institution as a string and not a multi select
								this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
									key: 'institution',
									value
								});
							} else {
								var _multitextInstitution = [];

								_forEach(value, (multiTextBlock)=>{
									if(multiTextBlock.name != ''){
										if(!_has(_multitextInstitution, multiTextBlock.type)){
											_multitextInstitution[multiTextBlock.type] = [];
										}
										_multitextInstitution[multiTextBlock.type].push(multiTextBlock.name);
									}
								});

								this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
									key: 'multitext',
									value: _multitextInstitution,
								});
							}
							break;			
						case 'originalArticleNumber':
						case 'originalReferencePages':
							// Toggle Switchers (Original Page Numbers / Article Number)
							if(value === ''){
								this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_CLEAR_UNSELECTED_OPTION', {
									groupName: 'originalPagesArticle',
									unselectedOptionName: key
								});
							} else {
								this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_SELECTED_OPTION', {
									groupName: 'originalPagesArticle',
									selectedOption: key,
								});
								this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_AT_GROUP', {
									groupName: 'originalPagesArticle',
									value: value,
								});
							}
							break;								
						case 'originalDoi':
						case 'originalUrl':
							// Toggle Switchers (Original DOI/URL)
							if(value === ''){
								this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_CLEAR_UNSELECTED_OPTION', {
									groupName: 'originalDoiUrl',
									unselectedOptionName: key
								});
							} else {
								this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_SELECTED_OPTION', {
									groupName: 'originalDoiUrl',
									selectedOption: key,
								});
								this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_AT_GROUP', {
									groupName: 'originalDoiUrl',
									value: value,
								});
							}
							break;
						case 'retractionArticleNumber':
						case 'retractionReferencePages':
							// Toggle Switchers (retraction Page Numbers / Article Number)
							if(value === ''){
								this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_CLEAR_UNSELECTED_OPTION', {
									groupName: 'retractionPagesArticle',
									unselectedOptionName: key
								});
							} else {
								this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_SELECTED_OPTION', {
									groupName: 'retractionPagesArticle',
									selectedOption: key,
								});
								this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_AT_GROUP', {
									groupName: 'retractionPagesArticle',
									value: value,
								});
							}
							break;								
						case 'retractionDoi':
						case 'retractionUrl':
						case 'retractionDOI':
						case 'retractionURL':
							// the capitol letters are for Magazine
							if(this.$store.state.referenceRules.referenceTypeId === 26){
								if(key === 'retractionDOI'){
									if(value === ''){	
										this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_CLEAR_UNSELECTED_OPTION', {
											groupName: 'retractionDoiUrl',
											unselectedOptionName: 'retractionDoi'
										});
									} else {
										this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_SELECTED_OPTION', {
											groupName: 'retractionDoiUrl',
											selectedOption: 'retractionDoi',
										});
										this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_AT_GROUP', {
											groupName: 'retractionDoiUrl',
											value: value,
										});
									}

								} else if(key === 'retractionURL'){
									if(value === ''){	
										this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_CLEAR_UNSELECTED_OPTION', {
											groupName: 'retractionDoiUrl',
											unselectedOptionName: 'retractionUrl'
										});
									} else {
										this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_SELECTED_OPTION', {
											groupName: 'retractionDoiUrl',
											selectedOption: 'retractionUrl',
										});
										this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_AT_GROUP', {
											groupName: 'retractionDoiUrl',
											value: value,
										});
									}

								}
							} else {
								// Toggle Switchers (retraction DOI/URL)
								if(value === ''){
									this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_CLEAR_UNSELECTED_OPTION', {
										groupName: 'retractionDoiUrl',
										unselectedOptionName: key
									});
								} else {
									this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_SELECTED_OPTION', {
										groupName: 'retractionDoiUrl',
										selectedOption: key,
									});
									this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_AT_GROUP', {
										groupName: 'retractionDoiUrl',
										value: value,
									});
								}
							}
							break;
						case 'length':
						case 'runtime':
							this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
								key,
								value,
							});
							this.emitter.emit('setRuntimeUI', {
								key
							});
							break;
						case 'publishers':
							var _multitextPublishers = {
								// object, field-type will be the key  
								'original': [],
								'reference': [],
							}

							// Publishers Only (value is reconstructed object)
							_forEach(value, (multiTextBlock)=>{
								if(multiTextBlock.name != ''){
									if(!_has(_multitextPublishers, multiTextBlock.type)){
										_multitextPublishers[multiTextBlock.type] = [];
									}
									_multitextPublishers[multiTextBlock.type].push({
										city: multiTextBlock.city || '',
										name: multiTextBlock.name,
										state: multiTextBlock.state || '',
									});
								}
							});
						
							this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
								key: 'publishers',
								value: _multitextPublishers,
							});
							break;
						default:
							// console.log(key);
							// console.log(value);
							// All other Reference Data
							this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
								key,
								value
							});

							// Datepicker Fields
							switch(key){
								case 'filedDate':
									AS_LoadDatepicker({
										id: 'RefWiz_FiledDate',
										loadDate: value,
										isRefWizard: true,
									});
									break;
								case 'originalPublicationDate':
									AS_LoadDatepicker({
										id: 'RefWiz_OriginalPublicationDate',
										loadDate: value,
										isRefWizard: true,
									});
									break;
								case 'publicationDate':
									AS_LoadDatepicker({
										id: 'RefWiz_PublicationDate',
										loadDate: value,
										isRefWizard: true,
									});
									break;
								case 'proposedDate':
									AS_LoadDatepicker({
										id: 'RefWiz_ProposedDate',
										loadDate: value,
										isRefWizard: true,
									});
									break;
								case 'retractionDate':
									AS_LoadDatepicker({
										id: 'RefWiz_RetractionDate',
										loadDate: value,
										isRefWizard: true,
									});
									break;
								case 'retrievalDate':
									AS_LoadDatepicker({
										id: 'RefWiz_RetrievalDate',
										loadDate: value,
										isRefWizard: true,
									});
									break;
							}//e:switch.key
					}//e:switch.key
				});//e:forEach
			
			}//e:if:else:isRefRulesEmpty

			this.emitter.emit('expandTextareaBus');

		});//e:nextTick
	},
	components: {
		ControlContributor,
		ControlPublishers,
		ControlToggleSelect,
		FormDatepicker,
		FormInputReferenceWizard,
		FormTextareaReferenceWizard
	},
}
