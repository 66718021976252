<!--
- Artwork
-- Reference Type Id: 9
-- 2024.06.20
-->

<template>
	<div>
	
		<section class="mb-5">
			<h3>Format Options</h3>

			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Museum"
								value="museum"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Type_Museum">
								Artwork in a museum
								<span class="svg bi-info-circle has-tooltip" data-bs-toggle="tooltip" data-bs-placement="bottom" title="This reference type is for citing a piece of art that is displayed in a museum, gallery, or their corresponding websites." role="tooltip" />
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Outside"
								value="outside"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Type_Outside">
								Artwork viewed outside a museum
								<span class="svg bi-info-circle has-tooltip" data-bs-toggle="tooltip" data-bs-placement="bottom" title="If the artwork is displayed outside a museum (like a public park or government building), use this reference type." role="tooltip" />
							</label>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-textarea-reference-wizard
				label="Artwork Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-bracket-help="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />

			<form-input-reference-wizard
				label="Date Produced"
				:help-text="calcDateProducedHelpText"
				key="dateProductedElse"
				:is-common-use="(referenceFormatTypeId == $enums.Format.MLA9) ? true : false"
				@generate-reference-preview="generateReferencePreview"
				v-model="publicationDate" />

			<form-input-reference-wizard
				label="Format / Medium"
				help-text='Enter the format or medium of the artwork. If needed, include the dimensions of the piece separated by a comma (Oil on canvas, 28 x 12").'
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="format" />

			<div v-if="type==='museum'">
				<control-publishers
					label="Museum or Collection Name"
					help-text-prop="Capitalize the name exactly as it appears."
					field-type="reference"
					:is-show-city-state="false"
					:is-single-only="true" /> 

				<form-input-reference-wizard
					label="Artwork Location: City"
					help-text="Type the name of the city where the artwork is located."
					:is-common-use="true"
					@generate-reference-preview="generateReferencePreview"
					v-model="city" />

				<form-input-reference-wizard
					label="Artwork Location: State"
					help-text="Type the state's two letter abbreviation (e.g. TN). Don't include for foreign countries."
					:is-common-use="true"
					@generate-reference-preview="generateReferencePreview"
					v-model="state" />

				<form-input-reference-wizard
					label="Artwork Location: Country"
					help-text="Include the name of the country even if it is domestic (e.g. United States)."
					:is-common-use="true"
					@generate-reference-preview="generateReferencePreview"
					v-model="country" />
			</div>

			<div v-else-if="type==='outside'">
				<control-publishers
					label="Publisher/Venue Name"
					help-text-prop="Type the full name exactly as it appears."
					field-type="reference"
					:is-show-city-state="true"
					:is-single-only="true" /> 
					
				<form-input-reference-wizard
					label="URL"
					help-text="Type or paste the entire URL. You can use a URL shortener if it is too long (e.g., bit.ly)."
					:is-common-use="true"
					@generate-reference-preview="generateReferencePreview"
					v-model="url" />
			</div><!--e:if:type==='museum'-->
		</section>
	
	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _forEach from 'lodash/forEach';
import config from '@/config';
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeArtwork',
	mixins: [mixinReferenceTypeGenesis],
	computed:{
		calcDateProducedHelpText(){
			if(this.referenceFormatTypeId === config.enums.Format.MLA9){
				return 'If the work was produced over time, separate the first and last date with a hyphen (e.g., 3 March - 2 May 2020).';
			}
			return 'If the work was produced over time, separate the first and last date with a hyphen (e.g., 2020 - 2021).';
		},
	},
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = [];

			if(this.isRefRulesEmpty) {
				// Load Fresh - Construct based on type and kind
				
				// Artist
				this.addContributorRole({
					addDefault: true,
					type: 'author',	// display as Artist
				});
				
				// Group Artist
				this.addContributorRole({
					type: 'groupAuthor', // display as Group Artist
				});

			} else {
				// Load Based on previously entered data in Step 3 (or an Edit)
				// Update Used Contributor Types
				this.usedContributorTypes = ['author', 'groupAuthor'];
			}//e:if:isRefRulesEmpty
			
			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},//e:calcContributors
	},
	mounted(){
		if(this.type === ''){
			this.type = 'museum';
		}
	},
}
</script>