<!--
- Charts & Maps
-- Reference Type Id: 12
-- 2024.01.31
-->

<template>
	<div>
		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>
		
		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-textarea-reference-wizard
				label="Title of the Work"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />

			<form-textarea-reference-wizard
				label="Description"
				help-text="Add a description for the kind of work you're using. If the work has a description for a title, don't add a description here."
				:is-autosize="true"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="description" />
			
			<form-input-reference-wizard
				label="Publication Year"
				help-text="Don't include a publication year for dynamic maps (e.g., Google Maps)."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="publicationDate" />

			<control-publishers
				label="Publisher or Producer"
				field-type="reference" />

			<form-input-reference-wizard
				label="URL (Web address)"
				help-text="Include a link to the equipment's website if applicable."
				@generate-reference-preview="generateReferencePreview"
				v-model="url" />

			<form-datepicker
				help-text="If the work can change, enter the date you accessed the work for your research (e.g., interactive maps)."
				id="RefWiz_RetrievalDate" 
				label="Retrieval Date"
				:is-today-button="true"
				:paper-format="0"
				@date-set="dateSetRetrieval"
				@date-clear="dateClearRetrieval" />

		</section>
	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import HelperDateIsReferenceWizardValid from '@/helpers/date-is-reference-wizard-valid.js';
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeChartsMaps',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = [];

			// Author
			this.addContributorRole({
				addDefault: true,
				type: 'author',
			});

			// Group Author
			this.addContributorRole({
				type: 'groupAuthor',
			});

			// User Name
			this.addContributorRole({
				type: 'userName',
			});

			if(!this.isRefRulesEmpty) {
				// Load Based on previously entered data in Step 3 (or an Edit)
				
				// Update Used Contributor Types
				this.usedContributorTypes = ['author','groupAuthor','userName'];

			}//e:if:isRefRulesEmpty

			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},//e:calcContributors

		dateClearRetrieval(){
			this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
				key: 'retrievalDate',
				value: ''
			});
			this.generateReferencePreview();
		},
		dateSetRetrieval(newDate){
			if(HelperDateIsReferenceWizardValid(newDate)){
				this.$store.commit('referenceRules/UPDATE_FORM_DATA', {
					key: 'retrievalDate',
					value: newDate
				});
				this.generateReferencePreview();
			}
		},
	},
}
</script>