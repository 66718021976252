var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          attrs: {
            label: "Title of the Work",
            "help-text":
              "Sacred titles can be written in Title Case. Subtitles that aren't sacred should be in sentence case.",
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.bookTitle,
            callback: function ($$v) {
              _vm.bookTitle = $$v
            },
            expression: "bookTitle",
          },
        }),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          attrs: {
            label: "Translated Title",
            "help-text":
              "If the original title is provide in another language, type the translated title.",
            "is-autosize": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.translatedTitle,
            callback: function ($$v) {
              _vm.translatedTitle = $$v
            },
            expression: "translatedTitle",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Publication Year",
            "help-text": "List the year this work was copyrighted/published.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.publicationDate,
            callback: function ($$v) {
              _vm.publicationDate = $$v
            },
            expression: "publicationDate",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Edition",
            "help-text":
              "For numbered editions, type the ordinal number (1st, 2nd, etc.). For named editions, type the name (e.g., 2nd ed., rev.).",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.edition,
            callback: function ($$v) {
              _vm.edition = $$v
            },
            expression: "edition",
          },
        }),
        _vm._v(" "),
        _c("control-publishers", { attrs: { "field-type": "reference" } }),
        _vm._v(" "),
        _c("control-toggle-select", {
          attrs: {
            label: _vm.toggleSelect.doiUrl.label,
            "is-common-use": _vm.toggleSelect.doiUrl.isCommonUse,
            options: _vm.toggleSelect.doiUrl.options,
            "group-name": "doiUrl",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Original Publication Information")]),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Original Publication Year",
            "help-text":
              "Add the abbreviation 'B.C.E.' after the year for ancient works (not 'B.C.'). If the date is approximate, place 'ca.' before the year (for circa).",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.originalPublicationDate,
            callback: function ($$v) {
              _vm.originalPublicationDate = $$v
            },
            expression: "originalPublicationDate",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }