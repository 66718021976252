var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          attrs: {
            label: "Title of the Material",
            "help-text": _vm.calcTitleHelpText,
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Format",
            "help-text":
              "If you entered a description for the title, don't add a format. Only capitalize the first word and proper nouns.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.format,
            callback: function ($$v) {
              _vm.format = $$v
            },
            expression: "format",
          },
        }),
        _vm._v(" "),
        _c("form-datepicker", {
          attrs: {
            id: "RefWiz_PublicationDate",
            label: "Presentation Date",
            "help-text": "Enter as much date information as is available.",
            "is-all-common-use": true,
            "is-common-use": true,
            "paper-format": 0,
          },
          on: {
            "date-set": _vm.dateSetPublication,
            "date-clear": _vm.dateClearPublication,
          },
        }),
        _vm._v(" "),
        _c("control-publishers", {
          attrs: {
            label: "Institution or Platform",
            "button-text": "another",
            "help-text-prop":
              "Enter the name of the institution where the lecture was given or the platform where it can be retrieved. For non-US institutions, enter the country instead of a state (e.g., London, UK).",
            "field-type": "reference",
            "is-show-city-state": true,
            "is-single-only": true,
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: { label: "URL (Web address)" },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.url,
            callback: function ($$v) {
              _vm.url = $$v
            },
            expression: "url",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }