var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "mb-5" }, [
      _c("h3", [_vm._v("Format Options")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [_vm._v("Choose a type")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Radio_Episode",
                  value: "episode",
                },
                domProps: { checked: _vm._q(_vm.type, "episode") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "episode"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "RefWiz_Radio_Episode" },
                },
                [_vm._v("\n\t\t\t\t\t\t\tSingle episode\n\t\t\t\t\t\t")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Radio_Series",
                  value: "series",
                },
                domProps: { checked: _vm._q(_vm.type, "series") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "series"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "RefWiz_Radio_Series" },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t\t\tAn entire television series\n\t\t\t\t\t\t"
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          attrs: {
            label: "Series Title",
            "help-text": _vm.calcTitleHelpText,
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v(" "),
        _vm.type === "episode"
          ? _c("form-textarea-reference-wizard", {
              attrs: {
                label: "Episode Title",
                "help-text": _vm.calcTitleHelpText,
                "is-autosize": true,
                "is-common-use": true,
                "is-suggest-title-case": true,
              },
              on: {
                "generate-reference-preview": _vm.generateReferencePreview,
              },
              model: {
                value: _vm.episodeTitle,
                callback: function ($$v) {
                  _vm.episodeTitle = $$v
                },
                expression: "episodeTitle",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "series",
              expression: "type === 'series'",
            },
          ],
          attrs: {
            label: "Years the Series Aired",
            "help-text":
              'Type the years that the series first aired. If the show is still being produced, the second year should be "present".',
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.publicationDate,
            callback: function ($$v) {
              _vm.publicationDate = $$v
            },
            expression: "publicationDate",
          },
        }),
        _vm._v(" "),
        _c("form-datepicker", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "episode",
              expression: "type === 'episode'",
            },
          ],
          attrs: {
            id: "RefWiz_PublicationDate",
            label: "Episode Date",
            "help-text": "Include the date the episode first aired.",
            "is-all-common-use": true,
            "is-common-use": true,
            "paper-format": 0,
          },
          on: {
            "date-set": _vm.dateSetPublication,
            "date-clear": _vm.dateClearPublication,
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "episode",
              expression: "type === 'episode'",
            },
          ],
          attrs: { label: "Season Number", "is-common-use": true },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.seasonNumber,
            callback: function ($$v) {
              _vm.seasonNumber = $$v
            },
            expression: "seasonNumber",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type === "episode",
              expression: "type === 'episode'",
            },
          ],
          attrs: { label: "Episode Number", "is-common-use": true },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.episodeNumber,
            callback: function ($$v) {
              _vm.episodeNumber = $$v
            },
            expression: "episodeNumber",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Where did the show originally air?",
            "help-text":
              "Enter the name of the channel or platform where the show originally aired (e.g., PBS, Netflix).",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.channel,
            callback: function ($$v) {
              _vm.channel = $$v
            },
            expression: "channel",
          },
        }),
        _vm._v(" "),
        _c("control-publishers", {
          attrs: {
            "is-show-city-state": false,
            label: "Production Company",
            "field-type": "reference",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Streaming Service",
            "help-text":
              "If you watched the show on a streaming service different than its original channel, enter it here (e.g., Hulu).",
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.platform,
            callback: function ($$v) {
              _vm.platform = $$v
            },
            expression: "platform",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }