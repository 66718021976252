<!--
- Films & Movies
-- Reference Type Id: 19
-- 2024.06.20
-->
<template>
	<div>
		<section class="mb-5">
			<h3>Format Options</h3>

			<!-- Type -->
			<div class="form-group mb-0 py-2">
				<label>Choose a type</label>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input 
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Film"
								value="film"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Type_Film">
								Domestic film
							</label>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								id="RefWiz_Type_Foreign"
								value="foreign"
								v-model="type"
								@change="calcContributors" /> 
							<label class="form-check-label" for="RefWiz_Type_Foreign">
								Film in another language
							</label>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="mb-5">
			<control-contributor 
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>
		
		<section class="mb-5">
			<h3>Publication Information</h3>
			
			<form-textarea-reference-wizard 
				label="Film Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />

			<form-textarea-reference-wizard 
				v-show="type === 'foreign'" label="Translated Film Title"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="translatedTitle" />

			<form-input-reference-wizard 
				label="Release Year"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="publicationDate" />

			<form-textarea-reference-wizard
				label="Special Edition Title"
				help-text="Only include a special edition title if it is different from the original film/movie (e.g., Extended edition)."
				:is-autosize="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="format" />

			<control-publishers
				button-text="company"
				label="Production Company"
				field-type="reference"
				:is-show-city-state="false"
				:show-help-text="false" /> 

			<form-input-reference-wizard
				label="Platform"
				help-text="If you are watching the film on a service, type the name of the service where you watched it."
				:is-common-use="(referenceFormatTypeId == $enums.Format.MLA9) ? true : false"
				@generate-reference-preview="generateReferencePreview"
				v-model="platform" />

			<form-input-reference-wizard 
				label="URL"
				help-text="Only include a URL if the film can only be accessed online. You can use a URL shortener if it is too long (e.g., bit.ly)."
				@generate-reference-preview="generateReferencePreview"
				v-model="url" />
		</section>
	</div>
</template>

<script>
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeFilms',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = [];

			this.addContributorRole({
				type: 'custom',
			});
			this.addContributorRole({
				addDefault: true,
				type: 'author',
			});

			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},//e:calcContributors
	},
	mounted() {
		if(this.type === ''){
			this.type = 'film';

			this.$nextTick(()=>{
				this.calcContributors();
			});
		}
	},
}
</script>