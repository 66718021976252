<!--
- Press Release
-- Reference Type Id: 35
-- 2024.05.03
-->

<template>
	<div>
		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>
	
		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-textarea-reference-wizard
				label="Title or Description"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="workTitle" />

			<control-publishers
				:is-show-city-state="false"
				label="Publisher or Organization Name"
				help-text-prop="Don't include business titles (e.g., Inc., Co, Ltd.)."
				field-type="reference" /> 

			<form-datepicker
				id="RefWiz_PublicationDate" 
				label="Publication Date"
				:paper-format="0"
				@date-set="dateSetPublicationDate"
				@date-clear="dateClearPublicationDate" />
			
			<control-toggle-select
				:label="toggleSelect.doiUrl.label"
				:is-common-use="toggleSelect.doiUrl.isCommonUse"
				:options="toggleSelect.doiUrl.options"
				group-name="doiUrl" />
		</section>
	</div>
</template>

<script>
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypePressRelease',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = ['author', 'groupAuthor'];

			this.addContributorRole({
				type: 'author',
			});

			this.addContributorRole({
				addDefault: true,
				type: 'groupAuthor',
			});

			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},//e:calcContributors

		dateClearPublicationDate(){
			this.publicationDate = '';
			this.generateReferencePreview();
		},
		dateSetPublicationDate(newDate){
			this.publicationDate = newDate;
			this.generateReferencePreview();
		},
	},
	mounted(){
		// this type has URL selected as the default
		this.$store.commit('referenceRules/UPDATE_TOGGLE_SELECT_SELECTED_OPTION', {
			groupName: 'doiUrl',
			selectedOption: 'url',
		});
	},
}
</script>