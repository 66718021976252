var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "mb-5" }, [
      _c("h3", [_vm._v("Format Options")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [_vm._v("Choose a type")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Unpublished",
                  value: "unpublished",
                },
                domProps: { checked: _vm._q(_vm.type, "unpublished") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "unpublished"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(0),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Preparation",
                  value: "preparation",
                },
                domProps: { checked: _vm._q(_vm.type, "preparation") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "preparation"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(1),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Submitted",
                  value: "submitted",
                },
                domProps: { checked: _vm._q(_vm.type, "submitted") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "submitted"
                    },
                    _vm.calcContributors,
                  ],
                },
              }),
              _vm._v(" "),
              _vm._m(2),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-textarea-reference-wizard", {
          attrs: {
            label: "Manuscript Title",
            "help-text": _vm.calcTitleHelpText,
            "is-autosize": true,
            "is-common-use": true,
            "is-suggest-title-case": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.workTitle,
            callback: function ($$v) {
              _vm.workTitle = $$v
            },
            expression: "workTitle",
          },
        }),
        _vm._v(" "),
        _c("form-input-reference-wizard", {
          attrs: {
            label: "Year Completed",
            "help-text":
              "Since manuscripts haven't been published yet, include the year the work was finished.",
            "is-common-use": true,
          },
          on: { "generate-reference-preview": _vm.generateReferencePreview },
          model: {
            value: _vm.publicationDate,
            callback: function ($$v) {
              _vm.publicationDate = $$v
            },
            expression: "publicationDate",
          },
        }),
        _vm._v(" "),
        _c("control-publishers", {
          attrs: {
            "button-text": "affiliation",
            label: "Institutional Affiliation",
            "help-text-prop": _vm.calcHelpTextInstitutionalAffiliation,
            "field-type": "reference",
          },
        }),
        _vm._v(" "),
        _c("control-toggle-select", {
          attrs: {
            label: _vm.toggleSelect.doiUrl.label,
            "is-common-use": _vm.toggleSelect.doiUrl.isCommonUse,
            options: _vm.toggleSelect.doiUrl.options,
            "group-name": "doiUrl",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "RefWiz_Type_Unpublished" },
      },
      [
        _vm._v("\n\t\t\t\t\t\t\tUnpublished manuscript\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            role: "tooltip",
            title:
              "Unpublished references are owned by the author, but can be found in some university libraries or departments.",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "RefWiz_Type_Preparation" },
      },
      [
        _vm._v("\n\t\t\t\t\t\t\tManuscript in preparation\n\t\t\t\t\t\t\t"),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            role: "tooltip",
            title: "This is a manuscript that is being worked on by an author.",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "form-check-label",
        attrs: { for: "RefWiz_Type_Submitted" },
      },
      [
        _vm._v(
          "\n\t\t\t\t\t\t\tManuscript submitted for publication\n\t\t\t\t\t\t\t"
        ),
        _c("span", {
          staticClass: "svg bi-info-circle has-tooltip",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            role: "tooltip",
            title:
              "This reference type is for manuscripts that have not yet been accepted by a publisher.  If the manuscript has been accepted, create an In-press article.",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }