var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "mb-5" }, [
      _c("h3", [_vm._v("Format Options")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group mb-0 py-2" }, [
        _c("label", [_vm._v("Choose a type")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Personal",
                  value: "personal",
                },
                domProps: { checked: _vm._q(_vm.type, "personal") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "personal"
                    },
                    _vm.typeChange,
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "RefWiz_Type_Personal" },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t\t\tAn unpublished interview you did yourself\n\t\t\t\t\t\t"
                  ),
                ]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type,
                    expression: "type",
                  },
                ],
                staticClass: "form-check-input",
                attrs: {
                  type: "radio",
                  id: "RefWiz_Type_Archive",
                  value: "archive",
                },
                domProps: { checked: _vm._q(_vm.type, "archive") },
                on: {
                  change: [
                    function ($event) {
                      _vm.type = "archive"
                    },
                    _vm.typeChange,
                  ],
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "RefWiz_Type_Archive" },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t\t\tAn unpublished interview in an archive, database, or library\n\t\t\t\t\t\t"
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "personal",
              expression: "type == 'personal'",
            },
          ],
          key: "divKindPersonal",
          staticClass: "form-group mb-0 py-2",
        },
        [
          _c("label", [
            _vm._v("What kind of personal interview are you referencing?"),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.kind,
                  expression: "kind",
                },
              ],
              staticClass: "form-select",
              attrs: {
                "aria-label":
                  "What kind of personal interview are you referencing?",
              },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.kind = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.calcContributors,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "named" } }, [
                _vm._v("\n\t\t\t\t\tInterview with a named person\n\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "anonymous" } }, [
                _vm._v(
                  "\n\t\t\t\t\tInterview with an anonymous person (name withheld for confidentiality)\n\t\t\t\t"
                ),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.type == "archive",
              expression: "type == 'archive'",
            },
          ],
          key: "divKindArchive",
          staticClass: "form-group mb-0 py-2",
        },
        [
          _c("label", [
            _vm._v("What kind of archived interview are you referencing?"),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.kind,
                  expression: "kind",
                },
              ],
              staticClass: "form-select",
              attrs: {
                "aria-label":
                  "What kind of archived interview are you referencing?",
              },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.kind = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.calcContributors,
                ],
              },
            },
            [
              _c("option", { attrs: { value: "named" } }, [
                _vm._v("\n\t\t\t\t\tInterview with a named person\n\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "anonymous" } }, [
                _vm._v(
                  "\n\t\t\t\t\tInterview with an anonymous person (name withheld for confidentiality)\n\t\t\t\t"
                ),
              ]),
            ]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("control-contributor", {
          attrs: {
            "contributors-prop": _vm.contributors,
            "is-add-another": _vm.calcIsAddAnother,
            "used-contributor-types": _vm.usedContributorTypes,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "mb-5" },
      [
        _c("h3", [_vm._v("Publication Information")]),
        _vm._v(" "),
        _c("form-datepicker", {
          attrs: {
            "paper-format": 0,
            id: "RefWiz_PublicationDate",
            label: "Publication Date",
          },
          on: {
            "date-clear": _vm.dateClearPublication,
            "date-set": _vm.dateSetPublication,
          },
        }),
        _vm._v(" "),
        _vm.type == "personal"
          ? _c("control-publishers", {
              attrs: {
                label: "Location of Interview",
                "help-text-prop":
                  "Enter the city & state where the interview happened if relevant.",
                "field-type": "reference",
                "is-show-city-state": true,
                "is-single-only": true,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.type == "archive"
          ? _c("control-publishers", {
              attrs: {
                label: "Archive, Database, or Library",
                "help-text-prop":
                  "Type the full name of the archive, database, or library where the interview can be found (e.g., Library of Congress, Interview Archives).",
                "field-type": "reference",
                "is-show-city-state": true,
                "is-single-only": true,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.type == "archive"
          ? _c("form-input-reference-wizard", {
              attrs: {
                label: "Database or Archive Identifier",
                "help-text":
                  "If the interview has a number identifying it, enter it exactly as it appears (e.g., Interview 74B).",
                "is-common-use": true,
              },
              on: {
                "generate-reference-preview": _vm.generateReferencePreview,
              },
              model: {
                value: _vm.number,
                callback: function ($$v) {
                  _vm.number = $$v
                },
                expression: "number",
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }