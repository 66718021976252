<!--
- Speech
-- Reference Type Id: 41
-- 2024.05.15
-->

<template>
	<div>
		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>

		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-textarea-reference-wizard
				label="Title of the Speech"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="title" />

			<control-publishers
				field-type="reference" /> 

			<form-datepicker
				id="RefWiz_PublicationDate" 
				label="Date of the Speech"
				:is-all-common-use="true"
				:is-common-use="true"
				:paper-format="0"
				@date-set="dateSetPublication"
				@date-clear="dateClearPublication" />

			<form-input-reference-wizard
				label="Copyright Year"
				help-text="This is the year the album was copyrighted and/or released."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="copyrightYear" />

			<form-input-reference-wizard
				label="URL (Web address)"
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="url" />
		</section>
	</div>
</template>

<script>
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeSpeech',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = ['author'];

			this.addContributorRole({
				addDefault: true,
				type: 'author', //speaker
			});

			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});
		},//e:calcContributors
	},
	
}
</script>