<!--
- Informal Works
-- Reference Type Id: 22
-- 2024.01.30
-->

<template>
	<div>
		<section class="mb-5">
			<control-contributor
				:contributors-prop="contributors"
				:used-contributor-types="usedContributorTypes" />
		</section>
	
		<section class="mb-5">
			<h3>Publication Information</h3>

			<form-textarea-reference-wizard
				label="Title of the Work"
				:help-text="calcTitleHelpText"
				:is-autosize="true"
				:is-common-use="true"
				:is-suggest-bracket-help="true"
				:is-suggest-title-case="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="workTitle" />

			<form-input-reference-wizard
				label="Date Completed"
				help-text="Enter the date or year the work was completed or created."
				:is-common-use="true"
				@generate-reference-preview="generateReferencePreview"
				v-model="publicationDate" />

			<form-input-reference-wizard
				label="Description or Format"
				help-text="Include a description/format for works that include document numbers or other identifying information."
				@generate-reference-preview="generateReferencePreview"
				v-model="description" />
			
			<control-publishers
				button-text="another"
				help-text-prop="List the location where the work is stored."
				label="Archive, Repository, or Database Name"
				field-type="reference" /> 

			<control-toggle-select
				:label="toggleSelect.doiUrl.label"
				:is-common-use="toggleSelect.doiUrl.isCommonUse"
				:options="toggleSelect.doiUrl.options"
				group-name="doiUrl" />

		</section>

	</div>
</template>

<script>
import mixinReferenceTypeGenesis from '@/components/reference-rules-genesis/mixin-reference-type';

export default {
	name: 'RefTypeInformalWorks',
	mixins: [mixinReferenceTypeGenesis],
	methods: {
		calcContributors(){
			// set default values
			this.usedContributorTypes = [];

			this.addContributorRole({
				addDefault: true,
				type: 'author',
			});

			this.addContributorRole({
				type: 'editor',
			});
			
			this.addContributorRole({
				type: 'groupAuthor',
			});
			
			if(!this.isRefRulesEmpty) {
				// Load Based on previously entered data in Step 3 (or an Edit)
				
				// Update Used Contributor Types
				this.usedContributorTypes = ['author', 'groupAuthor', 'editor'];
				
			}//e:if:isRefRulesEmpty

			this.$nextTick(()=>{
				this.sortContributorTypes();
				this.generateReferencePreview();
			});

		},//e:calcContributors
	},
}
</script>